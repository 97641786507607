import Navbarr from "./components/navbar/Navbarr";
import SideBar from "./components/sideBar/SideBar";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Anime from './components/animes/Anime';
import CommingSoon from './components/commingSoon/CommingSoon.js';
import Watch from './components/watch/Watch';
import Favorite from './components/favorites/Favorite';
import Settings from './components/settings/Settings';
import TopRated from './components/topRated/TopRated';
import Movie from './components/movies/Movie';
import Serie from './components/series/Serie';
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import NotFound from "./components/NotFound";
import "./App.css";
import Discovery from './components/discovery/Discovery';

function App() {
    return (
        <BrowserRouter>
            <Navbarr />
            <SideBar />
            <Routes>
                <Route path="/movies" element={<Movie />} />
                <Route path="/:id" element={<Watch />} />
                <Route path='/animes' element={<Anime />} />
                <Route path='/series' element={<Serie />} />
                <Route path="/" element={<Discovery />} />
                <Route path="/:category?/topRated" element={<TopRated />} />
                <Route path="/favorites" element={<Favorite />} />
                <Route path="/settings" element={<Settings />} />
                <Route path='/commingSoon' element={<CommingSoon />} />
                <Route path='/login' element={<LogIn />} />
                <Route path='/signup' element={<SignUp />} />
                <Route path='/*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
